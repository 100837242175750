"use client";
import { Row } from "antd";
import styled from "styled-components";

export default styled(Row)`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  opacity: 1;
  .text {
    font-size: 20px;
    .subtext {
      font-size: 16px;
      color: gray;
    }
  }
`;
