export const IBRAHIM_LINK = "http://www.cs.bilkent.edu.tr/~korpe/homepage/index.html"
export const METIN_LINK = "https://www.linkedin.com/in/metinemenullahi/"
export const PROJECT_SPECIFICATION_REPORT = "/files/T2429_Project_Specification_Document.pdf"
export const ANALYSIS_AND_REQUIREMENTS_REPORT = "/files/T2429_Analysis_and_Requirements_Report.pdf"
export const DEMO_PRESENTATION = "/files/Demo_Presentation.pdf"
export const CIFTLIK_MAIL = "dev.ciftlik@gmail.com"

export const HASAT_PL= "https://docs.google.com/document/d/1c_fYRYYCTLHji-kGiEVTtLNX0wj30JGbrk9F_y2yJss/edit?usp=sharing"
export const EREN_PL = "https://docs.google.com/document/d/1IV6BkYxdgnH22vBJ-8LI-KV8o-AaEB4oWd32hRUKEds/edit?usp=sharing"
export const OZAN_PL = "https://docs.google.com/document/d/13dPq36nnUMU-zhqfQbJq2uZ3P1YIIBjcwhPIpzaWk9s/edit?usp=sharing"
export const ZEYNEP_PL = "https://docs.google.com/document/d/17uPJP5vT_Pa2FCzFalZiHkx_XqElZswH_4W9AnVG6Gw/edit?usp=sharing"
export const EMIR_PL = "https://docs.google.com/document/d/1oPZQb2Tdd385jTw6fGwGbh9RzJW7FIAaX6F8llr8kPk/edit?usp=sharing"