import { ReactNode } from "react";

import StyledHeader from "./styles";

const Header: React.FC<{ children?: ReactNode }> = (props) => {
  return (
    <StyledHeader>
      <img src="/images/the-logo.png" alt="Logo" className="header-logo" />
    </StyledHeader>
  );
};

export default Header;
