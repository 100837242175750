import { Layout } from 'antd';
import styled from 'styled-components';


export default styled(Layout.Footer)`
  width: 100%;
  z-index: 999;
  height: 66px!important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #001529;


  .text {
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
`;
