import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #f3f3f3;
  position: relative; 
  z-index: 0; 

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/the-background.jpg'); 
    background-size: cover;
    background-position: center;
    opacity: 0.25; 
    z-index: -1; 
  }
`;
